import React from "react"
import { Link } from "gatsby"
import Section from "../components/Section/Section"
import Layout from "../components/Layout/Layout"
import HubspotForm from "react-hubspot-form"
import "../styles/contact.scss"
import "../styles/raconteur.scss"
import Seo from "../components/Seo/Seo"

import LogoHabito from "../images/companyLogos/logo-habito.jpg"
import LogoHackaton from "../images/companyLogos/logo-hackaton.jpg"
import LogoFinom from "../images/companyLogos/logo-finom.jpg"
import LogoRaconteur from "../images/companyLogos/raconteur.jpg"

const Raconteur = () => {
  return (
    <Layout hideForm>
      <Seo title="Raconteur | Resistant.AI" />

    <Section sectionClass="Raconteur__body">

        <div className="w-wrap">
          <div className="w3-5">
            <h1 className="MainSection__title">Taking the Offensive<span className="highlighted"> Against Fraudsters</span></h1>
            <img src={LogoRaconteur} alt="Raconteur" className="LogoRaconteur" />

            <h3>Hunting criminals for over 15 years</h3>
            <p className="justified">
            We've been using machine learning to hunt cybercriminals for over 15 years. We can help you:
            </p>

            <p className="list__item">
              Detect document forgery, serial fraud, synthetic  identities, bots, account takeovers, money laundering, and unknown financial threats operating at scale
            </p>
            <p className="list__item">
              Identify malicious activity and keep fraudulent  sign-ups to a minimum
            </p>
            <p className="list__item">
              Use machine learning technology to stop fraudulent attempts and expose bad actors
            </p>
          </div>


          <div className="w2-5 Contact__form">
            <HubspotForm
            portalId="5669546"
            formId="5a740313-6c1e-4648-9944-c16f26230b80"
            region="na1"
            />
          </div>

        </div>

      </Section>

      <Section sectionClass="Contact__info">

      <div className="w-wrap">

        <a href="https://resistant.ai/blog/finom-selects-resistant-ais-transaction-forensics-to-strengthen-its-money-laundering-defences/" target="blank" className="w1-3">
          <img src={LogoFinom} alt="Finom"/>
          <h3>Anti-Money Laundering</h3>
          <p className="main-blue">FINOM selects selected Resistant AI to bolster its anti-money laundering measures</p>
        </a>

        <a href="/case-studies/habito" target="blank" className="w1-3">
          <img src={LogoHabito} alt="Habito"/>
          <h3>Detect Document Forgery</h3>
          <p className="main-blue">How Habito increased fraud detection by 30%</p>
        </a>

        <a href="https://resistant.ai/blog/resistant-ai-awarded-digital-crime-fighter-of-the-year-for-winning-inaugural-hackathon-at-acams-hollywood/" target="blank" className="w1-3">
          <img src={LogoHackaton} alt="Hackathon PWC"/>
          <h3>Crime Fighter of the Year</h3>
          <p className="main-blue">Resistant’s AI powered software identified patterns of anomalous and suspicious behavior tied to human trafficking</p>
        </a>

      </div>

      </Section>

    </Layout>
  )
}

export default Raconteur
